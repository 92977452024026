import React, { useEffect } from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Img from 'gatsby-image'
import styles from '../styles/global.css'
import FabEmailUs from '../components/fabEmail'
import PropTypes from 'prop-types'
import { config } from '@fortawesome/fontawesome-svg-core'

// Disable the auto CSS insertion
config.autoAddCss = false

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`

const HomePage = ({ data }) => {
  return (
    <Layout>
      <SEO description={data.site.siteMetadata.description} title={data.site.siteMetadata.title} lang={'en'} />
      <FabEmailUs />
      <h1>ArtfulBits IT AB</h1>
      <a
        href={'https://www.linkedin.com/company/artfulbits-it-ab'}
        target="_in"
        title={'Find Us on LinkedIn'}
        className="socialLink"
      >
        <FontAwesomeIcon icon={['fab', 'linkedin']} style={{ color: '#0077B5' }} size={'4x'} className="test" />
      </a>
      <a
        href={'https://www.facebook.com/artfulbits.se'}
        target="_fb"
        title={'Facebook Company Page'}
        className="socialLink"
      >
        <FontAwesomeIcon icon={['fab', 'facebook']} style={{ color: '#4267b2' }} size={'4x'} />
      </a>
      {/** https://admin.appointlet.com/organizations/113089/bookables/143954 */}
      <img 
          src="https://www.appointletcdn.com/loader/buttons/F62459.png" 
          data-appointlet-organization="artfulbits-it-ab" />
      <script src="https://www.appointletcdn.com/loader/loader.min.js" async="" defer="" />
      {/** Footer */}
      <footer style={{ position: 'absolute', bottom: '24px', textAlign: 'left', left: '24px' }}>
        <p>
          Organisationnummer:
          <br />
          559197-2392
          <br />
          VAT-nr:
          <br />
          SE559197239201
        </p>
      </footer>
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HomePage
