import React from 'react'
import logo from '../assets/images/artfulbits-se-logo-1024x1024.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../styles/fab.css'
import { config } from '@fortawesome/fontawesome-svg-core'

// Disable the auto CSS insertion
config.autoAddCss = false

const FabEmailUs = () => {
  return (
    <a href={'mailto://sales@artfulbits.se'} target="_ab" className="socialLink" title={'Write email to Us'}>
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img src={logo} className="logoAction" width={'128px'} height={'128px'} alt={'ArtfulBits Logo'} />
          </div>
          <div className="flip-card-back">
            <FontAwesomeIcon icon={['fas', 'envelope']} style={{ color: '#db241f', paddingTop: '32px' }} size={'4x'} />
          </div>
        </div>
      </div>
    </a>
  )
}

export default FabEmailUs
