import React from 'react'
import ReactHelmet from 'react-helmet'
import PropTypes from 'prop-types'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

const Layout = ({ children }) => {
  library.add(fab, fas)

  return <div className="container">{children}</div>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
